<template>
  <div>
    <vs-table stripe :data="stats">
      <template>
        <vs-tr :key="key" v-for="(value, key) in stats">
          <vs-td>
            {{ key }}
          </vs-td>

          <vs-td>
            <div @click="getStatKey(key)">
            {{ value }}
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  props: ["stats"],
   data() {
    return {
     
    };
  },
  methods: {
    getStatKey(key) {
      this.$emit("stats-clicked", key);
    }
  },
  mounted() {
  },
};
</script>
