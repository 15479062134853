<template>
  <div>
    <vue-apex-charts
      ref="apexChart"
      type="pie"
      :options="options"
      :series="options.series"
      @dataPointSelection="clickHandler"
    >
    </vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: ["level_counts"],
  components: {
    VueApexCharts,
  },
  data() {
    return {
      options: {
        series: this.level_counts.series,
        chart: {
          type: "pie",
        },
        labels: this.level_counts.labels,
        dataLabels: {
          formatter: function (value) {
            return Math.round(value);
          },
        },
        legend: {
          position: "bottom",
        },
      },
    };
  },
  methods: {
    clickHandler(event, chartContext, config) {
      this.$emit(
        "level-clicked",
        this.level_counts.labels[config.dataPointIndex]
      );
    },
  },
};
</script>

<style>
</style>