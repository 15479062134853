<template>
  <div>
    <vs-row>
      <vs-col vs-w="6" v-if="gmStats !== null">
        <!-- v-if="gmStats !== null" -->
        <h4>GMs</h4>
        <vs-table stripe :data="gmStats">
          <template>
            <vs-tr :key="gmStat[0]" v-for="gmStat in gmStats">
              <vs-td>
                {{ gmStat[0] }} {{ getCity(gmStat[0]) }}
              </vs-td>

              <vs-td>
                <div @click="showSpocs(gmStat[0])">
                  {{ gmStat[1] }}
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-col>
      <vs-col vs-w="6" v-if="spocStats !== null">
        <h4>SPOCs</h4>
        <vs-table stripe :data="spocStats">
          <template>
            <vs-tr :key="spocStat[0]" v-for="spocStat in spocStats">
              <vs-td>
                {{ spocStat[0] }}
              </vs-td>

              <vs-td>
                <div @click="getCandidates(spocStat[1],spocStat[0])">
                  {{ spocStat[1].length }}
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
// import axios from "axios";
import moment from "moment";
import eventbus from '../eventbus';
export default {
  props:["stat_data_details"],
  data() {
    return {
      gmStats: null,
      spocStats: null,
      citys: [],
      candidate_detail:null,
      stat_data:[],
      gm_name:"",
      candidate_name:"",
    };
  },
  mounted() {
    // console.log("hiii",this.stat_data_details)
    this.citys  = this.stat_data_details
    this.stat_data = this.stat_data_details
    this.getGmStats();
    eventbus.$on('stat-drilldown-data',data =>{
      // console.log("eventcall--", data);
      this.stat_data = data
      this.citys = data
      // this.citys = data
      this.getGmStats();
      this.spocStats = null;
      // console.log("this.stat_data",this.stat_data)
    })

    
  },
  methods: {
    getCandidates(candidates,name) {
        this.$emit("spocs-clicked", candidates,name,this.gm_name);
        // this.candidate_name = name
        // console.log("candidate_naem",name)
    },
    getHumanTime(timestamp) {
      return moment.unix(timestamp).format("DD-MMM-YYYY HH:mm");
    },
    getCity(name){
      for (let i = 0; i < this.citys.length; i++) {
        if (this.citys[i].gm == name) {
          return "  [ " +this.citys[i].gm_city.slice(0,1)+ " ]"
        }
      }
    },
    getGmStats() {
      let gmStats = {};
      this.stat_data.forEach((candidate) => {
        if (gmStats[candidate.gm] === undefined) {
          gmStats[candidate.gm] = 1;
        } else {
          gmStats[candidate.gm]++;
        }
        /* if (spocStats[candidate.spoc_name] === undefined) {
          spocStats[candidate.spoc_name] = {
            count: 1,
            gm: candidate.gm,
          };
        } else {
          spocStats[candidate.spoc_name].count++;
        } */
      });
      const gms = Object.entries(gmStats);
      this.gmStats = gms.sort((a, b) => b[1] - a[1]);
      this.gmStats.unshift(["All", this.stat_data.length]);
      console.log(this.gmStats)
      // const spocs = Object.entries(spocStats);
      // this.spocStats = spocs.sort((a, b) => b[1].count - a[1].count);
    },
    showSpocs(gm) {
      let spocStats = {};
      const value = null;
      this.$emit("spocs-clicked", value);
      this.stat_data.forEach((candidate) => {
        if (gm !== "All" && candidate.gm === gm) {
          spocStats = this.pushIntoSpocStats(candidate, spocStats);
        }
        if (gm === "All") {
          spocStats = this.pushIntoSpocStats(candidate, spocStats);
        }
      });
      const spocs = Object.entries(spocStats);
      this.spocStats = spocs.sort((a, b) => b[1].length - a[1].length);
      // console.log("Show _spoc",this.spocStats)
      this.gm_name = gm
      console.log("gm",gm)
    },
    pushIntoSpocStats(candidate, spocStats) {
      if (spocStats[candidate.spoc_name] === undefined) {
        spocStats[candidate.spoc_name] = [candidate];
      } else {
        spocStats[candidate.spoc_name].push(candidate);
      }
      return spocStats;
    },
  },
};
</script>

<style>
</style>