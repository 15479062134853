<template>
  <div>
    <vs-card>
      <div>
        <h4>{{ gm_name }} - {{ candidate_name }} - [{{ candidates.length }}]</h4>
      </div>
      <vs-table stripe :data="candidates" class="mt-4">
        <template slot="thead">
          <vs-th> Can-ID </vs-th>
          <vs-th> Name </vs-th>
          <vs-th> Level </vs-th>
          <vs-th> Last Created </vs-th>
          <vs-th> Comment </vs-th>
          <vs-th> Spoc Name </vs-th>
          <vs-th> Action </vs-th>
        </template>
        <template>
          <vs-tr v-for="candidate in candidates" :key="candidate.id">
            <vs-td>{{ candidate.identity }}</vs-td>
            <vs-td>{{ candidate.person_name }}</vs-td>
            <vs-td>{{ candidate.level }}</vs-td>
            <vs-td>{{ getHumanTime(candidate.last_call) }}</vs-td>
            <vs-td>{{ candidate.engagement_details }}</vs-td>
            <vs-td>{{ candidate.spoc_name }}</vs-td>
            <vs-td
              ><vs-button
                @click="openPopupEmit(candidate.id)"
                color="dark"
                icon="info_outline"
                size="large"
                type="flat"
              ></vs-button
            ></vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["candidates", "candidate_name", "gm_name"],
  data: () => ({}),
  mounted() {
  },
  methods: {
    openPopupEmit(mwb_id) {
      console.log("mwb id check", mwb_id);
      this.openBigPopup(mwb_id);
    },
    getHumanTime(timestamp) {
      return moment.unix(timestamp).format("DD-MMM-YYYY HH:mm");
    },
  },
};
</script>
