<template>
  <div>
    <vs-card title="Spoc Data">
      <vs-row>
        <vs-col
          style="font-size: 14px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
          ><b>Webinar List : </b></vs-col
        >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
          <v-select
            class="w-full"
            placeholder="Search by webinar topic"
            label="title"
            :options="webinars"
            v-model="selected_webinar"
          ></v-select>
        </vs-col>
        <!-- <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          v-if="show_upload_btn"
        >
          <vs-button @click="uploadWebinarData">Upload</vs-button>
        </vs-col> -->
      </vs-row>
    </vs-card>
    <!-- v-if="current_ctc_alert"  -->
    <div v-if="show_alert_message" class="alert_message">
      {{ alert_message }}
    </div>
    <vs-card style="margin-top: 1%" v-if="selected_webinar !== null">
      <vs-row>
        <vs-col vs-justify="center" vs-w="6">
          <webinar-stats-table
            :stats="stats"
            v-if="stats !== null"
            @stats-clicked="getWebinarStatsDrillDown"
          >
          </webinar-stats-table>
        </vs-col>
        <vs-col vs-type="flex" vs-align="center" vs-justify="center" vs-w="6">
          <levels-pie
            :level_counts="level_counts"
            v-if="level_counts !== null"
            @level-clicked="fetchLevelData"
          >
          </levels-pie>
        </vs-col>
      </vs-row>
    </vs-card>

    <vs-collapse type="margin">
      <!-- v-if="level_data !== null" -->
      <vs-collapse-item :key="gm" v-for="(candidates, gm) in level_data">
        <div slot="header">
          <h5>
            {{ gm }} [{{ candidates.length }}] [{{
              candidates[0].gm_city.slice(0, 1)
            }}]
          </h5>
        </div>
        <div>
          <webinar-candidates-table
            :candidates="candidates"
          ></webinar-candidates-table>
          <!-- <vs-table stripe :data="candidates">
            <template slot="thead">
              <vs-th> Can-ID </vs-th>
              <vs-th> Name </vs-th>
              <vs-th> Level </vs-th>
              <vs-th> Last Created </vs-th>
              <vs-th> Comment </vs-th>
              <vs-th> Spoc Name </vs-th>
              <vs-th> Action </vs-th>
            </template>
            <template>
            <vs-tr v-for="candidate in candidates" :key="candidate.id">
              <vs-td>{{ candidate.identity }}</vs-td>
              <vs-td>{{ candidate.person_name }}</vs-td>
              <vs-td>{{ candidate.level }}</vs-td>
              <vs-td>{{ getHumanTime(candidate.last_call) }}</vs-td>
              <vs-td>{{ candidate.engagement_details }}</vs-td>
              <vs-td>{{ candidate.spoc_name }}</vs-td>
              <vs-td
                ><vs-button
                  @click="openPopupEmit(candidate.id)"
                  color="dark"
                  icon="info_outline"
                  size="large"
                  type="flat"
                ></vs-button
              ></vs-td>
            </vs-tr>
            </template>
          </vs-table> -->
        </div>
      </vs-collapse-item>
    </vs-collapse>
    <vs-card>
      
        <vs-row  v-if="stat_data !== null">
          <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <h4>
              {{ drilldown_title }}
            </h4>
          </vs-col>
          <vs-col vs-w="1" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <vs-dropdown vs-custom-content vs-trigger-click>
              <a class="a-icon" href.prevent @click="showTreeBox">
                Levels
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login customDropDown">
                <treeselect
                  v-model="initSelectedLevels"
                  :multiple="true"
                  :options="treeDataLevels"
                />
              </vs-dropdown-menu>
            </vs-dropdown>
          </vs-col>
          <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <h5>Search Criteria:</h5>
          </vs-col>
          <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start" vs-align="center"
          style="overflow-y: scroll"
          >
            <div v-for="(chip, index) in levels" :key="index">
            <vs-chip
              v-if="chip !== 'divider'"
              color="primary"
            >
              <b>{{ chip }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
          </vs-col>
          <vs-col vs-w="1" vs-type="flex" vs-justify="flex-end" vs-align="center">
            <vs-button icon="search" @click="SearchData"></vs-button>
          </vs-col>
        </vs-row>
        <div v-if="stat_details.length != 0 && stat_data !== null" class="mt-3">
        <stats-drilldown-component
          :stat_data_details="stat_details"
          @spocs-clicked="getCandidates"
        ></stats-drilldown-component>
      </div>
      <div v-if="stat_details.length == 0 && stat_data !== null" class="mt-4">
       <p style="text-align:center">No Data Available</p>
      </div>
    </vs-card>
    
    <div v-if="candidates_details !== null">
      <webinar-candidates-table
        :candidates="candidates_details"
        :candidate_name="candidate_name"
        :gm_name="gm_name"
      ></webinar-candidates-table>
    </div>
  </div>
</template>

<script>
//Pie charts
import LevelsPie from "../components/WebinarDashboardComponents/LevelsPie.vue";
//Tables
import WebinarStatsTable from "../components/WebinarDashboardComponents/WebinarStatsTable.vue";
import WebinarCandidatesTable from "../components/WebinarDashboardComponents/WebinarCandidatesTable.vue";
import StatsDrilldownComponent from "../components/WebinarDashboardComponents/StatsDrilldownComponent.vue";
import constants from "../../constants.json";
import vSelect from "vue-select";
import axios from "axios";
import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import eventbus from "../components/eventbus";
// import eventbus from '../eventbus';
export default {
  data() {
    return {
      show_upload_btn: false,
      show_alert_message: false,
      alert_message: "",
      webinars: [],
      selected_webinar: null,
      gm_name:"",
      candidate_name:"",
      level_counts: null,
      candidate_data: [],
      initSelectedLevels: [],
      stats: null,
      level_data: null,
      stat: null,
      spoc_ids: "",
      stat_data: null,
      stat_details: [],
      drilldown_title: "Gabbar",
      candidates_details: null,
      webinar_id: "",
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
                {
                  id: "M6 AR",
                  label: "M6 AR",
                },
                {
                  id: "M6 AR-",
                  label: "M6 AR-",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                // {
                //   id: "L2",
                //   label: "L2",
                // },
                // {
                //   id: "L3",
                //   label: "L3",
                // },
                // {
                //   id: "L4",
                //   label: "L4",
                // },
                // {
                //   id: "L6",
                //   label: "L6",
                // },
                // {
                //   id: "L5",
                //   label: "L5",
                // },
                // {
                //   id: "L3+",
                //   label: "L3+",
                // },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M1",
                  label: "M1",
                },
                // {
                //   id: "L4-",
                //   label: "L4-",
                // },
                // {
                //   id: "L1",
                //   label: "L1",
                // },
              ],
            },
            {
              id: "ENROLLED",
              label: "ENROLLED",
              children:[
                {
                  id: "M7",
                  label: "M7",
                },
                {
                  id: "M7-",
                  label: "M7-",
                },
                {
                  id: "M7+",
                  label: "M7+",
                },
                {
                  id: "M7#",
                  label: "M7#",
                },
                {
                  id: "M7-JJ",
                  label: "M7-JJ",
                },
                {
                  id: "M7X",
                  label: "M7X",
                },
                {
                  id: "M7D1",
                  label: "M7D1",
                },
                {
                  id: "M7D2",
                  label: "M7D2",
                },
                {
                  id: "M8",
                  label: "M8",
                },
                {
                  id: "M8+",
                  label: "M8+",
                },
                {
                  id: "M8-",
                  label: "M8-",
                },
                {
                  id: "M9",
                  label: "M9",
                },
                {
                  id: "M9-",
                  label: "M9-",
                },
                {
                  id: "M10",
                  label: "M10",
                },
                {
                  id: "M7-IR",
                  label: "M7-IR",
                },
                {
                  id: "M7-INT",
                  label: "M7-INT",
                },
                
              ]
            },
            {
              id: "Dnd",
              label: "Dnd",
              children:[
              {
              id: "N/A",
              label: "N/A",
            },
            {
              id: "DND",
              label: "DND",
            },
              ]
            }
          ],
        },
      ],
      levels: [],
    };
  },
  components: {
    WebinarStatsTable,
    WebinarCandidatesTable,
    LevelsPie,
    StatsDrilldownComponent,
    Treeselect,
    "v-select": vSelect,
  },
  methods: {
    // openPopupEmit(mwb_id) {
    //   console.log("mwb id check", mwb_id);
    //   this.openBigPopup(mwb_id);
    // },
    fetchWebinars() {
      axios
        .get("https://mileswebinars.2x2.ninja/api/pastWebinars")
        .then((response) => {
          console.log("pastWebinar", response.data);
          const webinars = [];
          response.data.forEach((w) => {
            let webinar = w;
            webinar.title = `${moment
              .unix(webinar.start_time_unix)
              .format("DD-MMM-YYYY")} : ${w.topic}`;
            webinars.push(webinar);
          });
          this.webinars = webinars;
        });
    },
    fetchLevelData(level) {
      let url = "";
      let encodedLevel = encodeURIComponent(level);
      this.level_data = null;
      this.stat_data = null;
      const level_data = {};
      if (this.spoc_ids != "") {
        url = `https://mileswebinars.2x2.ninja/api/levelwiseCandidates/${this.selected_webinar.id}?level=${encodedLevel}&spoc_ids=${this.spoc_ids}`;
      }
      if (this.spoc_ids == "") {
        url = `https://mileswebinars.2x2.ninja/api/levelwiseCandidates/${this.selected_webinar.id}?level=${encodedLevel}`;
      }
      // const url = `https://mileswebinars.2x2.ninja/api/levelwiseCandidates/${this.selected_webinar.id}?level=${encodedLevel}&spoc_ids=${this.spoc_ids}`;
      axios.get(url).then((response) => {
        // console.log(response.data);
        response.data.forEach((candidate) => {
          if (level_data[candidate.gm] === undefined) {
            level_data[candidate.gm] = [candidate];
          } else {
            level_data[candidate.gm].push(candidate);
          }
        });
        this.level_data = level_data;
        // console.log("level_data", this.level_data);
      });
    },
    getGmReportingSpocs() {
      let gm_id = localStorage.getItem("spoc_id");
      let ids = [];
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getGmReportingSpocs", response);
          for (let i = 0; i < response.data.spocs.length; i++) {
            const element = response.data.spocs[i];
            ids.push(element.id);
          }
          this.spoc_ids = ids.join();
          console.log("getGmReportingSpocs spoc_ids", this.spoc_ids);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    uploadWebinarData() {
      if (this.stats.upload_status == 0) {
        this.show_alert_message = true;
        this.alert_message = "Data Upload";
      } else if (this.stats.upload_status == 1) {
        this.show_alert_message = true;
        this.alert_message =
          " Data Being Uploaded Please Check After Some Time";
      } else if (this.stats.upload_status == 2) {
        this.show_alert_message = true;
        this.alert_message = " Data Has Been Uploaded";
      }

      // console.log("this.webinar_id", this.webinar_id)
      let obj = {
        webinar_id: this.webinar_id,
      };
      console.log(obj);
      let url = `${constants.MILES_WEBINARS}uploadWebinarsData`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          // this.$vs.notify({
          //   title: response.data.message,
          //   color: "success",
          // });
          // this.show_upload_btn = false;
          // this.show_alert_message = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getHumanTime(timestamp) {
      return moment.unix(timestamp).format("DD-MMM-YYYY HH:mm");
    },
    getWebinarStatsDrillDown(key) {
      this.level_data = null;
      this.drilldown_title = key;
      this.initSelectedLevels = [];
      this.levels = [];
      this.candidates_details = null;
      if (key == "Attended") {
        this.stat = "attended";
      }
      if (key == "Interested") {
        this.stat = "interested";
      }
      if (key == "Connected") {
        this.stat = "connected";
      }
      if (key == "Non Connected") {
        this.stat = "not_connected";
      }
      if (key == "Pending Net Enquiry Insertion") {
        this.stat = null;
      }
      if (key == "Not yet in MWB") {
        this.stat = null;
      }
      if (key == "Registered") {
        this.stat = null;
      }
      if (key == "Pre Enrolled") {
        this.stat = "pre_enrolled";
      }
      if (key == "Post Enrolled") {
        this.stat = "post_enrolled";
      }
      if (key == "Dialed") {
        this.stat = "dailed";
      }
      if (key == "Not Dialed") {
        this.stat = "not_dailed";
      }
      this.$vs.loading();
      this.stat_data = null;
      axios
        .get(
          `https://mileswebinars.2x2.ninja/api/webinarStatsDrillDown/${this.selected_webinar.id}?category=${this.stat}`
        )
        .then((response) => {
          console.log("getWebinarStatsDrillDown", response);
          this.stat_data = response.data;
          this.stat_details = response.data;
          this.$vs.loading.close();
          // eventbus.$emit("stat-drilldown-data", this.stat_details);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    SearchData() {
      this.stat_details = [];
      const stat = this.stat_data;
      // console.log("search btn",this.stat_details.length)
      var details = [];
      this.candidates_details = null;
      if(this.levels.length != 0){
        for (let i = 0; i < stat.length; i++) {
        const element = stat[i];
        for (let j = 0; j < this.levels.length; j++) {
          const level = this.levels[j];
          if (element.level == level) {
            details.push(element);
          }
        }
        this.stat_details = details;
      }
      }else{
        this.stat_details = this.stat_data
      }
      // this.stat_details = [];
      // console.log("finally Get", this.stat_details);
      eventbus.$emit("stat-drilldown-data", this.stat_details);
    },
    getCandidates(candidates,name,gm_name) {
      this.candidates_details = candidates;
      this.gm_name = gm_name
      this.candidate_name = name
    },
    showTreeBox() {
      return this.treeDataLevels;
    },
    mergeAllSearch() {
      this.levels = [];
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.levels.push(child.label);
          });
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.levels.push(child.label);
          });
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.levels.push(child.label);
          });
        } else if (sort === "ENROLLED") {
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.levels.push(child.label);
          });
        }
        else if (sort === "Dnd") {
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.levels.push(child.label);
          });
        }else {
          this.levels.push(sort);
        }
      });
      // console.log("levels",this.levels)
      // console.log(this.initSelectedLevels)
    },
    // showUploadButton(stats){
    //   if(stats.upload_status == '0'){
    //     return true;
    //   }
    //   return false;
    // }
  },
  mounted() {
    this.fetchWebinars();
    if (this.getTeam("Admin")) {
      this.spoc_ids = "";
    }
    if (this.getTeam("GM")) {
      this.getGmReportingSpocs();
    }
    if (!this.getTeam("GM") && !this.getTeam("Admin")) {
      this.spoc_ids = localStorage.getItem("spoc_id");
    }
  },
  watch: {
    selected_webinar() {
      this.show_upload_btn = false;
      this.show_alert_message = false;
      this.level_data = null;
      this.stat_data = null;
      this.candidates_details = null;
      if (this.selected_webinar != null) {
        this.level_counts = null;
        this.stats = null;
        console.log("selected", this.selected_webinar);
        this.webinar_id = this.selected_webinar.webinar_id;
        axios
          .get(
            `https://mileswebinars.2x2.ninja/api/webinarStats/${this.selected_webinar.id}`
          )
          .then((response) => {
            console.log(response.data);
            // console.log("this.selected_webinar.id",this.selected_webinar.id)
            this.stats = {
              Registered: response.data.registered,
              Attended: response.data.attended,
              Interested: response.data.interested,
              "Pending Net Enquiry Insertion":
                response.data.pending_net_enquiry_insertion,
              "Not yet in MWB": response.data.not_in_mwb,
              Connected: response.data.connected,
              "Non Connected": response.data.not_connected,
              "Pre Enrolled": response.data.pre_enrolled,
              "Post Enrolled": response.data.post_enrolled,
              Dialed: response.data.dailed,
              "Not Dialed": response.data.not_dailed,
              upload_status: response.data.upload_status,
            };
            if (response.data.upload_status == 0) {
              this.show_upload_btn = true;
            }
            // else if (response.data.upload_status == 1) {
            //   this.show_alert_message = true
            //   this.alert_message = ' Data Being Uploaded Please Check After Some Time'
            // } else if (response.data.upload_status == 2) {
            //   this.show_alert_message = true
            //   this.alert_message = ' Data Has Been Uploaded'
            // }
            const M7_plus_levels = [
              "M7",
              "M7-",
              "M7-JJ",
              "M7#",
              "M7+",
              "M7D1",
              "M7D2",
              "M7X",
              "M8-",
              "M8+",
              "M8",
              "M9-",
              "M9",
              "M10",
            ];
            this.level_counts = {
              series: [],
              labels: [],
              m7_above_counts: [],
              m7_above_levels: [],
            };
            let enrolled_count = 0;
            if (response.data.levels != null) {
              response.data.levels.forEach((level) => {
                if (M7_plus_levels.includes(level.level)) {
                  enrolled_count++;
                  console.log("Enrolled Count", enrolled_count);
                } else {
                  this.level_counts.series.push(level.level_count);
                  this.level_counts.labels.push(level.level);
                }
              });
              this.level_counts.labels.push("Pre-Enrolled");
              this.level_counts.series.push(response.data.pre_enrolled);
              this.level_counts.labels.push("Post-Enrolled");
              this.level_counts.series.push(response.data.post_enrolled);
            }
          });
      }
    },
    initSelectedLevels: function () {
      this.mergeAllSearch();
    },
    stat_details:function(val){
      console.log("stat_details",val.length)
    }
  },
};
</script>

<style scoped>
.alert_message {
  background: #31a231b5;
  /* color: rgba(var(--vs-danger), 1); */
  color: rgb(29, 23, 23);
  margin: 5px;
  padding: 10px;
  font-size: 15px;
  /* border: 2px solid; */
  border-radius: 5px;
}
.customDropDown {
  width: 200px;
}
.vs-dropdown--menu {
  width: 300px;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>